
import { AppSettings, setAdvancedSearchParams, setAppSettings, setCurrentPageable, setTableState, useAppSettings, useTableState } from '@/store/customerManagement';
import { Box, Button, Spacer, StackView, List, StackWrap, Modal, Typography, Icon } from '@telus-uds/components-web'
import { BulkSimStatusUpdateRequest, BulkSimStatusUpdateResponse, CustomerApiService, CustomerUserResponse, NetworkConfigurationService, Page, UsimStatus } from "@/api/index";
import { useRef, useState, useEffect } from 'react'
import ConfirmModal from '@/components/ToolBar/ConfirmModal';
import { queryParamsType } from '@/types/global';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { setNotification } from '@/store/submitNotification';
import { handleApiError } from '@/utils/error-handler';
import { TableType } from '@/types/Table';
import { StatusWarning } from '@telus-uds/palette-allium/build/rn/icons'
import { resetAdvancedSearchParams } from '@/components/Table/TableHeader/DataTableSearch';
import { useBulkOperations } from '@/context/BulkOperationsContext';
import { useSelectedRows } from '@/components/Table/DataTable/SelectedRowsProvider';
import { DeviceTableData } from '..';

interface DeleteDeviceProps {
  filteredDevices: any[] // fix this
  selectedRows?: DeviceTableData[]
  isModalOpen: boolean
  toggleModal: () => void
  redirect?: string
  setDevicesModal?: (value: boolean) => void
}

const DeleteDevice = ({ filteredDevices, selectedRows, isModalOpen, toggleModal, redirect, setDevicesModal }: DeleteDeviceProps) => {
  const appSettings: AppSettings = useAppSettings();
  const router = useRouter()
  const dispatch = useDispatch()
  const [alert, setAlert] = useState('')
  const { id: customerId } = router.query as unknown as queryParamsType
  const { pageSize } = useTableState(TableType.USER_LIST);
  const { clearSelectedRows } = useSelectedRows();
  const { addBulkOperation, removeBulkOperation } = useBulkOperations();
  let bulkOperationId: string;

  const handleDelete = () => {
    const ids = filteredDevices.map((item: any) => item.id)
    bulkOperationId = addBulkOperation('Deleting Devices', TableType.DEVICE_LIST);
    const data: BulkSimStatusUpdateRequest[] = filteredDevices.map((dataItem) => ({
      simId: Number(dataItem.usimId),
      siteId: Number(dataItem.siteId)
    }));
    clearSelectedRows();
    toggleModal();
    setDevicesModal && setDevicesModal(false);
    NetworkConfigurationService.bulkUpdateSimStatus(customerId, "DELETED", data)  // update with UsuimStatus.DELETED when the endpoint is ready
    .then(async (res: BulkSimStatusUpdateResponse) => {
      dispatch(setNotification({
        message: `SuccessFull Requests: ${res.successfullRequest}, Failed Requests: ${res.failedRequest}, ${res.failedRequest !== 0 ? `Error Message: ${res?.failedSims?.[0].errorMessage}` : ''}`,
        type: res.failedRequest !== 0 ? 'error' : 'success'
      }));
    }).catch((err: any) => {
      handleApiError(err, dispatch);
    }).finally(() => {
      dispatch(setAppSettings({ isLoading: false } as AppSettings));
      dispatch(setCurrentPageable({ page: 0 }));
      dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
      removeBulkOperation(bulkOperationId);
      redirect && router.push(redirect)
      // if (refreshCallback) {
      //   refreshCallback()
      // }
    });
  };

  useEffect(() => {
    dispatch(setAppSettings({ isLoading: false } as AppSettings))
  }, [])

  const Content = () => {
    return (
      <Box vertical={4}>
        <Typography>Do you want to delete the following devices?</Typography>
        <Spacer space={4}/>
        <List variant={{ size: 'medium' }}>
          {filteredDevices.map((user) => (
            <List.Item key={user.id}>{user.name}</List.Item>
          ))}
        </List>
        <Spacer space={4}/>
            {/* <Icon icon={StatusWarning} accessibilityLabel="Warning" variant={{ size: 'micro' }}/> */}
            {/* <Typography variant={{ bold: true }}>Deleting a device will deprovision it from the assigned site.</Typography> */}
            <Typography variant={{ bold: true }}>SIM(s) will be deleted, however for SIM(s) without K/OP/OPC values in CMP, the status will be displayed as “Disabled”</Typography>
      </Box>)
  }

  const CancelButton = () => {
    return (
      <Button
        onClick={() => {
          toggleModal();
        }}
        variant={{  priority: 'low', text: true }}
      >
        Cancel
      </Button>
    );
  }

  return (
    <>
      <div role="alert" aria-live="assertive" aria-atomic="true" id="alert-message" style={{ position: 'absolute', left: '-9999px' }}>{alert}</div>
      <ConfirmModal
        content={Content}
        isOpen={isModalOpen}
        setIsOpen={toggleModal}
        onConfirm={() => {
          setAlert('Confirm selected, devices were deleted');
          handleDelete()
        }}
        heading="Delete Devices"
        confirmButtonText="Delete"
        confirmButtonVariant={{ danger: true }}
        cancelButtonType={CancelButton}
      />
    </>
  )
}



export default DeleteDevice
