import {
  A11yText,
  Button,
  Card,
  IconButton,
  StackView,
  TextButton,
} from "@telus-uds/components-web";
// import { useSearchParams } from 'next/navigation';
import {
  AdvancedSearchParams,
  AppSettings,
  CustomerSearchFields,
  DeviceSearchFields,
  setAdvancedSearchPanelState,
  setAdvancedSearchParams,
  setAppSettings,
  setCurrentPageable,
  setGeneralSearchQuery,
  useAdvancedSearchPanelState,
  useAdvancedSearchParams,
  useAppSettings,
  UserSearchFields,
} from "@/store/customerManagement";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  AdvancedSearchWrapper,
  FormButtonContainer,
} from "@/styles/theme/styledComponents";
import { cardTheme, getStyling } from "@/styles/theme/themes";
import { Form } from "react-final-form";
import { FormApi } from "final-form";
import { useDispatch } from "react-redux";
import CustomerSearch from "../AdvancedSearch/CustomerSearch";
import DeviceSearch from "../AdvancedSearch/DeviceSearch";
import UserSearch from "../AdvancedSearch/UserSearch";
import FormContext from "./FormContext"; // Adjust the import path as needed
import { TableType } from "@/types/Table";
import { DEFAULT_PAGE_SIZE } from "@/utils/constants";
import TelusUserSearch from "./TelusUserSearch";
import { UserStatus } from "@/api/index";

export interface SearchProps {
  handleChange: (input: any) => (value: string) => void;
  customerId: number;
}

interface AdvancedSearchProps {
  tableType: TableType;
}

interface AdvancedSearchFieldProps {
  tableType: string;
  setSearchValues: (value: any) => void;
  searchValues: AdvancedSearchParams;
  setFilterDropDown: (value: boolean) => void;
  filterOpen: boolean;
  form: FormApi<AdvancedSearchParams>;
  customerId?: number;
}

const getInitialFormState = (tableType: string): AdvancedSearchParams => {
  switch (tableType) {
    case TableType.CUSTOMER_LIST:
      return {
        customerName: "",
        id: "",
        ban: "",
        customerStatus: [],
      } as CustomerSearchFields; // Explicit type assertion
    case TableType.USER_LIST:
      return {
        name: "",
        role: {
          label: '',
        },
        status: '' as UserStatus,
        username: "",
      } as UserSearchFields; // Explicit type assertion
    case TableType.TELUS_USER_LIST:
      return {
        name: "",
        role: {
          label: '',
        },
        status: '' as UserStatus,
        username: "",
      } as UserSearchFields; // Explicit type assertion
    case TableType.DEVICE_LIST:
      return {
        name: "",
        imsi: "",
        msisdn: "",
        profileName: "",
        siteName: [],
        status: [],
        dateActivated: "",
        createdAt: "",
        imeiLock: "",
      } as DeviceSearchFields; // Explicit type assertion
    default:
      return {};
  }
};

type ParsedValues = {
  [key: string]: any;
};

const isCommaSeparatedList = (value: any): boolean => {
  return typeof value === "string" && value.includes(",");
};

const parseFormValues = (values: { [key: string]: any }): ParsedValues => {
  const result: ParsedValues = {};

  Object.entries(values).forEach(([key, value]) => {
    // Automatically convert comma-separated strings to arrays based on content
    if (isCommaSeparatedList(value)) {
      value = value.split(",").map((item: string) => item.trim());
    }

    // Handle nested keys
    if (key.includes(".")) {
      let current: ParsedValues | any = result;
      const parts = key.split(".");
      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          current[part] = value;
        } else {
          current[part] = current[part] || {};
          current = current[part];
        }
      });
    } else {
      result[key] = value;
    }
  });

  return result;
};

const AdvancedSearchFields = (props: AdvancedSearchFieldProps) => {
  const { tableType, setSearchValues, searchValues, form, customerId } = props;

  const handleChange =
    ({ name, onChange }: { name: any; onChange: any }) =>
    (value: any) => {
      // console.log("Handle Change Triggle: ", value);

      setSearchValues({ ...searchValues, [name]: value });
      onChange({ target: { name, value } });
    };

  const componentsMapping: { [key: string]: JSX.Element } = {
    UserList: <UserSearch handleChange={handleChange} customerId={customerId}/>,
    TelusUserList: <TelusUserSearch handleChange={handleChange} customerId={customerId}/>,
    CustomerList: <CustomerSearch handleChange={handleChange} customerId={customerId}/>,
    DeviceList: (
      <DeviceSearch handleChange={handleChange} customerId={customerId} />
    ),
  };

  return componentsMapping[tableType];
};

const AdvancedSearch = ({
  tableType
}: AdvancedSearchProps) => {
  const dispatch = useDispatch();
  const formRef = useRef<typeof Form>(null);
  const initialFormState = useMemo(
    () => getInitialFormState(tableType),
    [tableType]
  );
  const [searchValues, setSearchValues] = useState<AdvancedSearchParams>({});
  const advancedSearchParams = useAdvancedSearchParams();
  const [resetKey, setResetKey] = useState(0);
  const advancedSearchOpen = useAdvancedSearchPanelState(); // Get the current state of the advanced search panel
 const { advancedSearchPanelState } = useAppSettings();
  // Extract customerId from URL
  const getCustomerIdFromUrl = (): number | null => {
    const path = window.location.pathname;
    const match = path.match(/\/customer\/(\d+)/); // This regex matches the customerId from the URL
    if (match?.[1]) {
      const parsedId = parseInt(match[1], 10); // Convert the customerId to a number
      if (!isNaN(parsedId)) {
        // console.log("PARSED CUSTOMER ID:", parsedId)
        return parsedId; // Return the customerId as a number
      }
    }
    return null; // Return null if no valid customerId is found
  };

  const customerId = getCustomerIdFromUrl();

  useEffect(() => {
    // console.log("TABLE TYPE CHANGED", tableType);
    setSearchValues({});
    dispatch(setGeneralSearchQuery({ searchQuery: "" }))
    dispatch(setAdvancedSearchParams(initialFormState));
  }, [dispatch, tableType]);

  const handleFormReset = () => {
    // Increment resetKey to force remounting of CheckboxGroup
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleToggleAdvancedSearch = () => {
    dispatch(setAppSettings({advancedSearchPanelState: !advancedSearchPanelState} as AppSettings)); // Toggle the visibility of the advanced search panel
  };

  // Clear function: Resets the advanced search params to initial state
  const onClear = useCallback(() => {
    setSearchValues({});
    dispatch(setAdvancedSearchParams(initialFormState));
  }, [dispatch, initialFormState]);

  const onSubmit = useCallback(
    (formValues: AdvancedSearchParams) => {
      const structuredFormValues = parseFormValues(searchValues);
      dispatch(setAdvancedSearchParams(structuredFormValues));
    },
    [dispatch, searchValues]
  );

  const CloseButton = () => {
    return (
      <StackView
        direction="row"
        tokens={{
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          action="close"
          onClick={() => {
            dispatch(setCurrentPageable({ page: 0 })) // Reset the page number to 0
            dispatch(setAppSettings({advancedSearchPanelState: false} as AppSettings));
            setSearchValues({});
            dispatch(setAdvancedSearchParams(initialFormState));
          }}
        />
      </StackView>
    );
  };

  return (
    <AdvancedSearchWrapper>
      <Form
        innerRef={formRef}
        onSubmit={onSubmit}
        initialValues={initialFormState}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <FormContext.Provider value={{ form, resetKey, setResetKey }}>
            <form onSubmit={handleSubmit}>
              <fieldset>
                <legend>
                  <A11yText text="Advanced Search Form" />
                </legend>
                <Card
                  tokens={getStyling(cardTheme)}
                  variant={{ background: "alternative" }}
                >
                  <CloseButton />

                  <AdvancedSearchFields
                    searchValues={searchValues}
                    setSearchValues={setSearchValues}
                    tableType={tableType}
                    filterOpen={advancedSearchOpen}
                    setFilterDropDown={handleToggleAdvancedSearch}
                    form={form}
                    customerId={customerId}
                  />
                  <FormButtonContainer type="search">
                    <Button
                      type="reset"
                      variant={{ text: true, priority: "low" }}
                      disabled={submitting || pristine}
                      onPress={() => {
                        form.reset();
                        handleFormReset();
                        onClear();
                      }}
                    >
                      Clear Filters
                    </Button>
                    <Button
                      variant={{ priority: "high" }}
                      disabled={submitting}
                      onPress={handleSubmit}
                      type="submit"
                    >
                      Apply
                    </Button>
                  </FormButtonContainer>
                </Card>
              </fieldset>
            </form>
          </FormContext.Provider>
        )}
      />
    </AdvancedSearchWrapper>
  );
};

export default AdvancedSearch;
