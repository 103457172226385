import { Button, Modal } from '@telus-uds/components-web';
import { useState } from 'react';

export const ConfirmModal = (props: any) => {
  const [alert, setAlert] = useState('')
  const ContentComponent = props.content;
  return (
    <>
      <div role="alert" aria-live="assertive" aria-atomic="true" id="alert-message" style={{ position: 'absolute', left: '-9999px' }}>{alert}</div>
      <Modal
        tokens={{ backdropOpacity: '0 !important' }}
        isOpen={props.isOpen}
        heading={props.heading}
        bodyText={props.bodyText}
        confirmButtonText={props.confirmButtonText}
        onClose={() => {
          props.setIsOpen(false);
        }}
        onCancel={() => {
          setAlert('Cancel selected, returning ')
          props.setIsOpen(false);
          if (props.onCancel) {
            props.onCancel();
          }
        }}
        onConfirm={() => {
          setAlert('Confirm selected, returning to original page')
          // props.setIsOpen(false);
          props.onConfirm();
        }}
        cancelButtonText="Cancel"
        variant={{ width: "l" }}
        {...(props.cancelButtonType && { cancelButtonType: props.cancelButtonType })}
        confirmButtonVariant={props.confirmButtonVariant ?? { priority: 'high' }}
      >
        {ContentComponent && <ContentComponent />}
      </Modal>
    </>
  );
};

export default ConfirmModal
