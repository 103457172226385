import { StyledLabelValueWrapper } from '@/styles/theme/styledComponents'
import { getStyling, labelTheme } from '@/styles/theme/themes'
import { Typography } from '@telus-uds/components-web'
import { Status } from './Status'
import React from 'react'
interface LabeledValueProps{
  label: string
  value: string | React.ReactNode
  description?: string
  active?: boolean
  isHeader?: boolean
}

const LabeledValue = ({ label, value, description, active, isHeader = false }: LabeledValueProps) => {
  return (
    <StyledLabelValueWrapper>
      <Typography variant={isHeader ? { bold: true, size: 'display1' } : {}}  tokens={getStyling(labelTheme)}>{label}</Typography>
      { label?.toLowerCase() === 'status'
        ? <Status message={value as string} active={active}/>
        : (
          typeof value === 'string' ? (
            <Typography variant={isHeader ? { bold: true, size: 'h1' } : { colour: 'h1' }} tokens={isHeader ? getStyling(labelTheme) : {}}>
              {value}
            </Typography>
          ) : (
            value
          )
        )
      }
      { description && <Typography variant={isHeader ? { bold: true, size: 'h1' } : { colour: 'h1' }} tokens={isHeader ? getStyling(labelTheme) : {}}>{description}</Typography> }
    </StyledLabelValueWrapper>
  )
}

export default LabeledValue
