import { CustomerApiService, UsimStatus } from "@/api/index";
import {
  CheckboxGroup,
  FlexGrid,
  Select,
  Spacer,
  StackView,
  TextInput,
  Typography
} from "@telus-uds/components-web";
import { useContext, useEffect, useState } from "react";
import { Field } from "react-final-form";
import { SearchProps } from ".";
import FormContext from "./FormContext"; // Adjust the import path as needed

const DeviceSearch = ({ handleChange, customerId }: SearchProps) => {
  const { resetKey } = useContext(FormContext);
  const [deviceSites, setDeviceSites] = useState([]);

  // console.log("DEVICES Customer ID:", customerId);

  // const customerId = customerId; // This should be dynamically set based on your application's context or passed as a prop

  useEffect(() => {
    const fetchSiteData = async () => {
      const response = await CustomerApiService.get(customerId);
      // console.log("RESPONSE FOR SITES:", response.sites);
      // Transform response to match the expected format for deviceSites
      const transformedSites = response.sites.map((userSite: any) => ({
        id: userSite.id.toString(),
        label: userSite.siteName,
      }));
      setDeviceSites(transformedSites);
    };

    fetchSiteData();
  }, [customerId]); // Re-fetch when customerId changes

  // console.log("DEVICES SITE DATA:", deviceSites);

  const deviceStatus = [
    { id: UsimStatus.ACTIVATED, label: "Activated" },
    { id: UsimStatus.DEACTIVATED, label: "Deactivated" },
    { id: UsimStatus.INVENTORY, label: "Inventory" },
    { id: UsimStatus.BLOCKED, label: "Blocked"},
    { id: UsimStatus.DISABLED, label: "Disabled" },
    { id: UsimStatus.DELETED, label: "Deleted" } // Uncomment this line when the endpoint is ready

  ];

  const imeiLock = [
    { id: "true", label: "Enabled" , value: true},
    { id: "false", label: "Disabled", value: false }
  ]


  return (
    <FlexGrid outsideGutter={false} limitWidth={false}>
      <FlexGrid.Row verticalAlign="middle">
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="name"
            render={({ input, meta }) => (
              <TextInput
                label="Name"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="imsi"
            render={({ input, meta }) => (
              <TextInput
                label="IMSI"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="msisdn"
            render={({ input, meta }) => (
              <TextInput
                label="MSISDN"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
      <Spacer space={2} />
      <FlexGrid.Row verticalAlign="top">
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="profileName"
            render={({ input, meta }) => (
              <TextInput
                label="Profile Name"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="imeiLock"
            render={({ input }) => (
              <StackView space={2}>
                <Select
                  label="IMEI Lock"
                  placeholder="Select a IMEI Status"
                  value={input.value ?? ''}
                  onChange={(newValue: string) => {
                    const value = newValue === '' ? undefined : newValue;
                    handleChange(input)(value)
                  }}
                >
                  {imeiLock.map(status => (
                    <Select.Item key={status.id} value={status.value}>
                      {status.label}
                    </Select.Item>
                  ))}
                </Select>
              </StackView>
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="status"
            render={({ input }) => (
              <StackView space={2}>
                <Typography variant={{ bold: true }}>Status</Typography>
                <CheckboxGroup
                  key={`deviceStatus-${resetKey}`}
                  initialCheckedIds={[]}
                  checkedId={input.value || []}
                  onChange={(newCheckedIds: string[]) => {
                    // input.onChange(newCheckedIds); // React Final Form's input.onChange will handle state update
                    // handleChange(input)(newCheckedIds);
                    const selectedLabels = newCheckedIds.map(
                      (id) =>
                        deviceStatus.find((status) => status.id === id)?.id
                    );
                    input.onChange(selectedLabels); // React Final Form's input.onChange will handle state update
                    handleChange(input)(selectedLabels.join(","));
                  }}
                  items={deviceStatus}
                />
              </StackView>
            )}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  );
};

export default DeviceSearch;
