import {
  BulkSimProfileUpdateRequest,
  BulkSimProfileUpdateResponse,
  BulkSimProvisionRequest,
  BulkSimProvisionResponse,
  BulkSimStatusUpdateRequest,
  BulkSimStatusUpdateResponse,
  ImeiLockRequest,
  NetworkConfigurationService,
  SiteResponse,
  UsimStatus
} from '@/api/index';
import { resetAdvancedSearchParams } from '@/components/Table/TableHeader/DataTableSearch';
import Loader from '@/components/ToolBar/Loader';
import TextIconButton from '@/components/ToolBar/TextIconButton';
import { useBulkOperations } from '@/context/BulkOperationsContext';
import {
  AppSettings,
  setAdvancedSearchParams,
  setAppSettings,
  setCurrentPageable,
  setGeneralSearchQuery,
  useAppSettings,
  useCurrentUserRole,
  useSelectedCustomer
} from '@/store/customerManagement';
import { setNotification } from '@/store/submitNotification';
import { ModalContentWrapper } from '@/styles/theme/styledComponents';
import { LocationMap } from '@telus-uds/palette-allium/build/web/icons';

import { TableType } from '@/types/Table';
import { queryParamsType } from '@/types/global';
import { handleApiError } from '@/utils/error-handler';
import {
  Button,
  FlexGrid,
  Icon,
  Modal,
  RadioGroup,
  Select,
  Skeleton,
  Spacer,
  StackView,
  StackWrap,
  Typography
} from '@telus-uds/components-web';
import arrayMutators from 'final-form-arrays';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { DeviceTableData } from '.';
import { useSelectedRows } from '../../../Table/DataTable/SelectedRowsProvider';
import DeleteDevice from './Table/DeleteDevice';
import { required } from '../../AccountCreation/validation';
import RemoveDevice from './Table/RemoveDevice';
import { isTelusAdmin } from '@/types/RoleMapping';

export interface DeviceActionType {
  actionType: 'editStatus' | 'editProfile' | 'createSIM' | 'removeSIM' | 'enableImeiLock';
}

interface DeviceActionsProps {
  actionType: DeviceActionType['actionType'];
  selectedData: DeviceTableData[];
  heading: string;
  modalOpen: boolean;
  setModalOpen: (data: boolean) => void;
  tableType?: TableType;
  setEditAction?: (data: any) => void;
  refreshCallback?: () => void;
}

interface ActionsProps {
  profiles?: any[];
  selectedData?: DeviceTableData[];
  assignedProfileId?: string;
  modalOpen?: boolean;
  siteId?: number;
}

const EditStatus = ({ currentStatus }: any) => {
  const currentUserRole = useCurrentUserRole();
  const statusValues = [
    { label: 'Activated', id: UsimStatus.ACTIVATED },
    { label: 'Deactivated', id: UsimStatus.DEACTIVATED },
    ...(isTelusAdmin(currentUserRole) ? [{ label: 'Delete SIM', id: UsimStatus.DELETED }] : []),
  ];
  const currentStatusId = currentStatus ? statusValues.find(item => item.id === currentStatus)?.id : null;
  return (
    <ModalContentWrapper>
      <Field
        name='status'
        validate={required}
        render={({ input, meta }) => (
          <>
            <RadioGroup
              items={statusValues}
              initialCheckedId={currentStatusId}
              legend='Status'
              value={input.id}
              onChange={(newValue: string) => input.onChange(newValue)}
            />
            {meta.modified && <Typography variant={{ colour: 'danger' }}>{meta.error}</Typography>}
          </>
        )}
      />
    </ModalContentWrapper>
  );
};

const EditProfile = ({ profiles, assignedProfileId }: ActionsProps) => {
  return (
    <ModalContentWrapper>
      <Field
        name='profile'
        validate={required}
        render={({ input, meta }) => (
          <>
            <RadioGroup
              items={profiles}
              legend='Profile'
              value={input.value}
              initialCheckedId={'' + assignedProfileId}
              onChange={(newValue: string) => {
                input.onChange(JSON.parse(newValue));
              }}
              {...(meta.modified &&
                meta.error && {
                  validation: 'error'
                })}
            />
            {meta.modified && <Typography variant={{ colour: 'danger' }}>{meta.error}</Typography>}
          </>
        )}
      />
    </ModalContentWrapper>
  );
};

const CreateSIM = ({ siteId }: ActionsProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [profileOptions, setProfileOptions] = useState([]);
  const { id: customerId } = router.query as unknown as queryParamsType;
  const customerSites = useSelectedCustomer().sites;
  const siteName = customerSites.find((site: SiteResponse) => site.id === Number(siteId))?.siteName;
  const appSettings = useAppSettings()
  const statusValues = [
    { label: 'Activated', id: UsimStatus.ACTIVATED },
    { label: 'Deactivated', id: UsimStatus.DEACTIVATED }
  ];

  useEffect(() => {
    if(siteId) {
      dispatch(setAppSettings({ isBeingSubmitted: true} as AppSettings))
        NetworkConfigurationService.listUsimProfilesByCustomerSiteId(customerId, siteId, false)
          .then((resp) => {
            const profileOptions = resp.content.map((profile: any) => ({
              name: profile.name,
              id: profile.id,
              label: profile.name
            }));
            setProfileOptions(profileOptions);
          })
          .catch((error) => {
            handleApiError(error, dispatch);
          })
          .finally(() => {
            dispatch(setAppSettings({ isBeingSubmitted: false} as AppSettings))
          });
    }
  }, [siteId]);

  
  return (
    <ModalContentWrapper key='createModalWrapper'>
      <StackWrap direction='column' space={4}>
        {appSettings.isBeingSubmitted
         ? <Skeleton characters={6} sizeIndex={3} lines={3} />
         : <FlexGrid>
          
          <StackWrap tokens={{ alignItems: 'center' }}>
            <Icon icon={LocationMap} variant={{ size: 'medium', color: 'brand' }} />
            <Typography variant={{  size: 'h3', colour: 'alternative1' }}>{siteName}</Typography>
          </StackWrap>
          <Spacer space={2} />
          <StackView space={2}>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                return (
                  <Field
                    name={`profile`}
                    render={({ input, meta }) => {
                      return (
                        <>
                          {(
                            <Select
                              label='Profile'
                              testID={'profile-select'}
                              placeholder={'Select Profile'}
                              value={input.value ? JSON.stringify(input.value) : ''}
                              onChange={(newValue: string) => {
                                input.onChange(JSON.parse(newValue));
                              }}
                              {...(meta.modified &&
                                meta.error && {
                                  validation: 'error'
                                })}
                            >
                              {profileOptions.map((profile: any) => <Select.Item key={profile.id} value={JSON.stringify({ id: profile.id, name: profile.name })}>
                                {profile.name}
                              </Select.Item>
                              )}
                            </Select>
                          )}
                          {meta.modified && <Typography variant={{ colour: 'danger' }}>{meta.error}</Typography>}
                        </>
                      );
                    }}
                  />
                );
              }}
            </FormSpy>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                return (
                  <Field
                    name={`status`}
                    render={({ input, meta }) => {
                      return (
                        <>
                          {(
                            <Select
                              label='Status'
                              testID={'status-select'}
                              placeholder={'Select Status'}
                              value={input.value ? JSON.stringify(input.value) : ''}
                              onChange={(newValue: string) => {
                                input.onChange(JSON.parse(newValue));
                              }}
                              {...(meta.modified &&
                                meta.error && {
                                  validation: 'error'
                                })}
                            >
                              { statusValues.map((status: any, index: number) => (
                                <Select.Item key={index} value={JSON.stringify({ id: status.id, name: status.label })}>
                                  {status.label}
                                </Select.Item>
                              ))}
                            </Select>
                          )}
                          {meta.modified && <Typography variant={{ colour: 'danger' }}>{meta.error}</Typography>}
                        </>
                      );
                    }}
                  />
                );
              }}
            </FormSpy>
          </StackView>
        </FlexGrid>}
      </StackWrap>
    </ModalContentWrapper>
  );
};

const EnableImeiLock = ({currentImeiStatus}: {currentImeiStatus: string}) => {
  const imeiLock = [
    { id: "true", label: "Enable" },
    { id: "false", label: "Disable" }
  ]
  return (
    <>
      <Spacer space={4} />
        <Field
          name='enableImeiLock'
          validate={required}
          render={({ input, meta }) => (
            <RadioGroup
              items={imeiLock}
              initialCheckedId={currentImeiStatus?.toString()}
              legend='IMEI Lock'
              value={input.value}
              onChange={(newValue: string) => input.onChange(newValue)}
            />
          )}
        />
      <Spacer space={4} />
    </>
  )
}

const DeviceActions = ({ actionType, heading, selectedData, modalOpen, setModalOpen, tableType, setEditAction, refreshCallback }: DeviceActionsProps) => {
  const [profiles, setProfiles] = useState([]);
  const router = useRouter();
  const dispatch = useDispatch();
  const { clearSelectedRows } = useSelectedRows();
  const [currentStatus, setCurrentStatus] = useState<string>(null);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [currentImeiStatus, setCurrentImeiStatus] = useState(null)
  const { id: customerId } = router.query as unknown as queryParamsType;
  const [deleteModal, setDeleteModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);

  const toggleDeleteModal = () => setDeleteModal((prevState) => !prevState);
  const toggleRemoveModal = () => setRemoveModal((prevState) => !prevState);

  const currentSiteId = selectedData[0]?.siteId;

  const { addBulkOperation, removeBulkOperation } = useBulkOperations();

  const onSubmit = (form: any) => {
    if (form.status === UsimStatus.DELETED) {
      toggleDeleteModal();
      return
    } 
    setModalOpen(false);
    let bulkOperationId: string;
    clearSelectedRows();

    if (actionType === 'editStatus') {
      bulkOperationId = addBulkOperation('Updating SIM Status', TableType.DEVICE_LIST);
      const data: BulkSimStatusUpdateRequest[] = selectedData.map((dataItem) => ({
        simId: Number(dataItem.usimId),
        siteId: Number(dataItem.siteId)
      }));

      NetworkConfigurationService.bulkUpdateSimStatus(customerId, form.status, data)
        .then(async (res: BulkSimStatusUpdateResponse) => {
          dispatch(setNotification({
            message: `Successfull Requests: ${res.successfullRequest}, Failed Requests: ${res.failedRequest}, ${res.failedRequest !== 0 ? `Error Message: ${res?.failedSims?.[0].errorMessage}` : ''}`,
            type: res.failedRequest === 0 ? 'success' : res.successfullRequest === 0 ? 'error' : 'warning',
            details: {
              operation: 'Bulk Update SIM Status',
              location: window.location.href,
              "Failed Requests": res.failedSims.map(sim => {
                return {
                  "target status:": form.status,
                  "imsi": sim.imsi,
                  "site": sim.siteName
                }
              })
            }
          }));
        }).catch((err: any) => {
          handleApiError(err, dispatch);
        }).finally(() => {
          dispatch(setAppSettings({ isLoading: false } as AppSettings));
          dispatch(setCurrentPageable({ page: 0 }));
          dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
          removeBulkOperation(bulkOperationId);
          if (refreshCallback) {
            refreshCallback()
          }
        });
    } else if (actionType === 'editProfile') {
      bulkOperationId = addBulkOperation('Updating SIM Profile', TableType.DEVICE_LIST);
      const data: BulkSimProfileUpdateRequest[] = selectedData.map((dataItem) => ({
        simId: Number(dataItem.usimId),
        profileId: form.profile
      }));
      NetworkConfigurationService.bulkUpdateSimProfile(customerId, currentSiteId, data)
        .then((res: BulkSimProfileUpdateResponse) => {
          dispatch(setNotification({
            message: `Successfull Requests: ${res.successfullRequest}, Failed Requests: ${res.failedRequest}, ${res.failedRequest !== 0 ? `Error Message: ${res?.failedSims?.[0].errorMessage}` : ''}`,
            type: res.failedRequest === 0 ? 'success' : res.successfullRequest === 0 ? 'error' : 'warning',
            details: {
              operation: 'Bulk Update SIM Profile',
              location: window.location.href,
              "Failed Requests": res.failedSims.map(sim => {
                return {
                  "target profile": sim.profileName,
                  "imsi": sim.imsi,
                  "site": sim.siteName
                }
              })
            }
          }));
        }).catch((err: any) => {
          handleApiError(err, dispatch);
        }).finally(() => {
          dispatch(setAppSettings({ isLoading: false } as AppSettings));
          dispatch(setCurrentPageable({ page: 0 }));
          dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
          removeBulkOperation(bulkOperationId);
          if (refreshCallback) {
            refreshCallback()
          }
        });
    } else if (actionType === 'createSIM') {
      bulkOperationId = addBulkOperation('Assigning Site', TableType.DEVICE_LIST);
      const bulkSiteProvisionRequest: BulkSimProvisionRequest[] = selectedData.map((dataItem) => (
        {
          simId: Number(dataItem.usimId),
          siteId: Number(currentSiteId),
          profileId: form?.profile?.id,
          status: form?.status?.id
        }
      ));
      NetworkConfigurationService.bulkProvisionSite(customerId, bulkSiteProvisionRequest)
        .then((res: BulkSimProvisionResponse) => {
          setEditAction((prev: any) => ({ ...prev, selectedData: undefined }));
          dispatch(setNotification({
            message: `SuccessFull Requests: ${res.successfullRequest}, Failed Requests: ${res.failedRequest}, ${res.failedRequest !== 0 ? `Error Message: ${JSON.stringify(res?.failedSims?.[0].errorMessage)}` : ''}`,
            type: res.failedRequest === 0 ? 'success' : res.successfullRequest === 0 ? 'error' : 'warning',
            details: {
              operation: 'Bulk Provision Site',
              location: window.location.href,
              "Failed Requests": res.failedSims.map(sim => {
                return {
                  "target profile": sim.profileName,
                  "target site": sim.siteName,
                  "imsi": sim.imsi,
                }
              })
            }
          }));
        }).catch((err: any) => {
          handleApiError(err, dispatch);
        }).finally(() => {
          dispatch(setAppSettings({ isLoading: false } as AppSettings));
          dispatch(setCurrentPageable({ page: 0 }));
          dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
          removeBulkOperation(bulkOperationId);
          if (refreshCallback) {
            refreshCallback()
          }
        });
    } else if (actionType === 'enableImeiLock') {

      const simIds: ImeiLockRequest = {
        usimIds: selectedData.map((dataItem) => dataItem.usimId)
      }
      bulkOperationId = addBulkOperation('Updating IMEI Lock', TableType.DEVICE_LIST);
      NetworkConfigurationService.updateSimImeiLock(customerId, currentSiteId, form.enableImeiLock, simIds)
        .then((res) => {
          let failedSims = 0, successfullSims = 0;
          res?.forEach((sim: any) => {
            if (sim?.error && sim?.error?.trim() !== "") {
              failedSims++;
            } else {
              successfullSims++;
            }
          });
          
          dispatch(setNotification({
            message:"Successfull Requests: " + successfullSims + ", Failed Requests: " + failedSims,
            type: 'success',
            details: {
              operation: 'Bulk Update IMEI Lock',
              location: window.location.href,
              "Failed Requests": res.map((sim: any) => {
                if (sim.error && sim.error.trim() !== "") {
                  return {
                    "imsi": sim.imsi,
                    "error": sim.error
                  }
                }
              })
            }
          }));
        }).catch((err: any) => {
          handleApiError(err, dispatch);
        }).finally(() => {
          dispatch(setAppSettings({ isLoading: false } as AppSettings));
          dispatch(setCurrentPageable({ page: 0 }));
          dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
          removeBulkOperation(bulkOperationId);
          if (refreshCallback) {
            refreshCallback()
          }
        });
    }
    dispatch(setAppSettings({ advancedSearchPanelState: false } as AppSettings));
    dispatch(setGeneralSearchQuery({ searchQuery: '' }));
    dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
  };


  useMemo(() => {
    if (actionType === 'editProfile') {
      NetworkConfigurationService.listUsimProfilesByCustomerSiteId(customerId, currentSiteId, false)
        .then((resp) => {
          const profileOptions = resp.content.map((profile: any) => ({
            id: '' + profile.id,
            label: profile.name
          }));
          setProfiles(profileOptions);
        }).catch((error) => {
          handleApiError(error, dispatch);
        });
    } else if (actionType == 'removeSIM') {
      toggleRemoveModal()
    }
  }, [actionType]);

  useMemo(() => {
    setCurrentStatus(selectedData[0]?.status);
    if (tableType === TableType.DEVICE_LIST || tableType === TableType.DEVICE_DETAILS) {
      setCurrentProfile(selectedData[0]?.usimProfileId);
    }
    setCurrentImeiStatus(selectedData[0]?.imeiLockEnabled)
  }, [selectedData, tableType]);

  const disableSubmit = (form: any, modified: any) => {
    const isPristine = !Object.values(modified).some(Boolean);
    if (actionType === 'editStatus') {
      return isPristine || !form.status || form.status === currentStatus;
    } else if (actionType === 'editProfile') {
      return isPristine || !form.profile || form.profile === currentProfile;
    } else if (actionType === 'createSIM') {
      return isPristine || !form.profile || !form.status;
    } else if (actionType === 'enableImeiLock') {
      return isPristine || !form.enableImeiLock || form.enableImeiLock.toString() === currentImeiStatus.toString();
    }
    return true; // Default case disable if actionType is not recognized
  };

  useEffect(() => {
    if (actionType === 'removeSIM') {
      toggleRemoveModal();
    }
  }, [actionType]);

  return (
    <>
      <DeleteDevice filteredDevices={selectedData} selectedRows={selectedData} isModalOpen={deleteModal} toggleModal={toggleDeleteModal} redirect={`/customer/${customerId}#devices`} setDevicesModal={setModalOpen}/>
      <Modal
        heading={heading}
        isOpen={modalOpen}
        variant={{ width: 'xl' }}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Loader>
          <StackView space={8}>
            <Form
              onSubmit={onSubmit}
              initialValues={{}}
              mutators={{
                ...arrayMutators
              }}
              render={({
                handleSubmit,
                form: {
                  getState
                }
              }) => {
                return <form onSubmit={handleSubmit}>
                  <fieldset>
                    {actionType === 'editStatus' && <EditStatus currentStatus={currentStatus} />}
                    {actionType === 'editProfile' && <EditProfile profiles={profiles} assignedProfileId={currentProfile} />}
                    {actionType === 'createSIM' && <CreateSIM key={'createSim'} siteId={currentSiteId}/>}
                    {actionType === 'enableImeiLock' && <EnableImeiLock currentImeiStatus={currentImeiStatus}/>}
                  </fieldset>
                  <StackView direction='row' space={2} tokens={{ alignItems: 'center' }}>
                    <Button
                      type='submit'
                      disabled={getState().hasValidationErrors || disableSubmit(getState().values, getState().modified)}
                      onClick={handleSubmit}
                      variant={{ priority: 'high' }}
                    >
                      Submit
                    </Button>
                    <TextIconButton
                      content='Cancel'
                      loaderRequired={false}
                      onClick={() => {
                        setModalOpen(false);
                      }}
                      theme={{ button: { color: 'secondary' } }}
                    />
                  </StackView>
                </form>}
              }
            />
          </StackView>
        </Loader>
      </Modal>
    </>
  );
};

export default DeviceActions;
