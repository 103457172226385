import { NoWrap } from "@/styles/theme/styledComponents";
import { Icon, Typography } from "@telus-uds/components-web";
import { StatusSuccess, StatusWarning, StatusError, Delete, LockClosed } from '@telus-uds/palette-allium/build/web/icons';
import { camelCase } from "./CamelCase";
import { NetworkProfileStatus, UsimStatus } from "@/api/index";

interface StatusProps {
    message: string
    active: boolean
}

export const Status = ({message, active}: StatusProps) => {
  const text = camelCase(message)
  let color = active ? 'positive' : 'warning';
  let iconVariant = active ? 'success' : 'warning';
  let icon = active ? StatusSuccess : StatusWarning;

  if (message === UsimStatus.INVENTORY) {
    color = 'light';
  }
  
  if(message === UsimStatus.DISABLED || message === NetworkProfileStatus.FAILED) {
    color = 'danger'
    iconVariant = 'danger';
    icon = StatusError;
  }

  if(message === UsimStatus.DELETED) {
    color = 'danger'
    iconVariant = 'danger';
    icon = Delete;
  }

  if (message === NetworkProfileStatus.FAILED) {
      color = 'negative';
  }

  if(message === UsimStatus.BLOCKED) {
    icon = LockClosed;
    color ='#676e73'
    iconVariant = 'greyShuttle';
  }
  
  return (
      <NoWrap>
          {message !== UsimStatus.INVENTORY && <Icon
            icon={icon}
            variant={{
              size: 'micro',
              color: iconVariant
            }}
          />}
          <Typography
            variant={{ colour: color }}
            tokens={{fontSize: '14px'}}>
            {" " + text}
          </Typography>
        </NoWrap>
  )
}
