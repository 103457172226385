import { useAppSettings } from "@/store/customerManagement";
import { DeploymentPlatforms } from "@/types/Deployments";


export const isDeployedOnGCP = () => {
    const appSettings = useAppSettings();
    return appSettings?.deploymentPlatform === DeploymentPlatforms.GCP;
}

export const isDeployedOnAWS = () => {
    const appSettings = useAppSettings();
    return appSettings?.deploymentPlatform === DeploymentPlatforms.AWS;
}

export const isDeployedOnEdge = () => {
    const appSettings = useAppSettings();
    return appSettings?.deploymentPlatform === DeploymentPlatforms.EDGE;
}

export const checkIsEqual = (platform: string) => {
    const appSettings = useAppSettings();
    return appSettings?.deploymentPlatform === platform;
}