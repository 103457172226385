import { NetworkConfigurationService, Role } from '@/api/index'
import TextIconButton from '@/components/ToolBar/TextIconButton'
import { useCurrentUserRole } from '@/store/customerManagement'
import { DropDown } from '@/styles/theme/styledComponents'
import { buttonDropDownTheme, disabledActionButtonTheme, disabledButtonTheme, dropDownItemTheme, dropDownTheme, getStyling, tableTopButtonTheme, tableTopIconTheme } from '@/styles/theme/themes'
import { isAnyViewerRole, isCustomerAdmin, isTelusAdmin } from '@/types/RoleMapping'
import { Button, Card, StackView } from '@telus-uds/components-web'
import { CaretDown, CaretUp, DownloadPdf } from '@telus-uds/palette-allium/build/web/icons'
import { useEffect, useState, useRef } from 'react'
import DeviceActions, { DeviceActionType } from '../DeviceActions'
import TableSizeSelect from '@/components/Table/TableHeader/TableSizeSelect'
import { TableType } from '@/types/Table'
import { DeviceTableData, EditProps } from '..'
import { queryParamsType } from '@/types/global'
import { useRouter } from 'next/router'
import { useBulkOperations } from '@/context/BulkOperationsContext'
import RemoveDevice from './RemoveDevice'
import ReactDOM from "react-dom";

interface TableButtonsProps {
  editAction: any
  setEditAction?: (data: EditProps) => void
  setModalOpen: (data: any) => void
  modalOpen: boolean
  handleExport?: () => void
  tableType: TableType
  handleToggleModal?: () => void
  refreshCallback?: () => void
}

const TableButtons = (props: TableButtonsProps) => {
  const router = useRouter();
  const [actionType, setActionType] = useState<DeviceActionType['actionType']>(null);
  const currentUserRole: Role = useCurrentUserRole();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { id: customerId } = router.query as unknown as queryParamsType;
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const portalNodeRef = useRef<HTMLElement | null>(null);
  const iconButtonRef = useRef<HTMLDivElement | null>(null);

  if (!portalNodeRef.current) {
    portalNodeRef.current = document.createElement("div");
  }

  useEffect(() => {
    const portalNode = portalNodeRef.current;
    document.body.appendChild(portalNode);

    return () => {
      document.body.removeChild(portalNode);
    };
  }, []);

  const toggleRemoveModal = () => setRemoveModal((prevState) => !prevState);

  const { hasOperation } = useBulkOperations();

  const handleDeviceActionClick = (actionType: DeviceActionType['actionType']) => {
    setActionType(actionType);
    setDropdownOpen(!dropdownOpen);
    setActionModalOpen(!actionModalOpen);
  };

  const handleRemoveDevice = () => {
    setActionType('removeSIM');
    setDropdownOpen(!dropdownOpen);
    toggleRemoveModal();
  };

  useEffect(() => {
    if (dropdownOpen && props.editAction?.selectedData?.length > 0 && (TableType.DEVICE_DETAILS === props.tableType)) {
      const firstRow = props.editAction.selectedData[0];
      NetworkConfigurationService.listUsimProfilesByCustomerSiteId(customerId, firstRow?.siteId, false)
        .then((resp) => {
          if (resp.content.length === 1) {
            props.editAction.editProfile = false;
          }
        }).catch((error) => {
          console.log("Could not fetch profiles", error);
        });
    }
  }, [dropdownOpen, props.editAction.selectedData]);

  const updateDropdownPosition = () => {
    if (dropdownRef.current && iconButtonRef.current) {
      const buttonRect = iconButtonRef.current.getBoundingClientRect();
      dropdownRef.current.style.top = `${buttonRect.bottom + window.scrollY}px`;
      dropdownRef.current.style.left = `${buttonRect.left}px`;
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      updateDropdownPosition();
      window.addEventListener('resize', updateDropdownPosition);
      window.addEventListener('scroll', updateDropdownPosition);
    } else {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition);
    }

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition);
    };
  }, [dropdownOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      iconButtonRef.current &&
      !iconButtonRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  let modalHeading
  if(actionType === 'editStatus') {
    modalHeading = 'Edit Status'
  } else if(actionType === 'editProfile') {
    modalHeading = 'Edit Profile'
  } else if (actionType === 'createSIM') {
    modalHeading = 'Create SIM in HSS'
  } else if (actionType === 'enableImeiLock') {
    modalHeading = 'Manage IMEI Lock'
  }

  return (
    <div style={{ marginRight: '8px' }}>
      <RemoveDevice filteredDevices={props.editAction.selectedData} selectedRows={props.editAction.selectedData} isModalOpen={removeModal} toggleModal={toggleRemoveModal} redirect={`/customer/${customerId}#devices`} setDevicesModal={props.setModalOpen} />
      {actionModalOpen && <DeviceActions
        tableType={props.tableType}
        heading={modalHeading}
        actionType={actionType}
        modalOpen={actionModalOpen}
        setModalOpen={setActionModalOpen}
        selectedData={props.editAction.selectedData}
        setEditAction={props.setEditAction}
        refreshCallback={props.refreshCallback}
      />}
      <StackView>
        <StackView space={4} divider tokens={{ justifyContent: 'flex-end' }} direction="row">
          <StackView>
            { !isAnyViewerRole(currentUserRole?.name) &&
                <div ref={iconButtonRef}>
                  <Button
                    tokens={!props.editAction.editable ? getStyling(disabledButtonTheme) : getStyling(buttonDropDownTheme)}
                    onPress={() => setDropdownOpen(!dropdownOpen)}
                    disabled={!props.editAction.editable || hasOperation(TableType.DEVICE_LIST)}
                    icon={dropdownOpen && props.editAction.editable ? CaretUp : CaretDown}
                    iconPosition="right"
                  >
                    Actions
                  </Button>
                </div>
            }
            {dropdownOpen && props.editAction.editable &&
              ReactDOM.createPortal(
                <DropDown ref={dropdownRef} style={{ position: 'absolute', zIndex: 999999 }}>
                  <Card tokens={getStyling(dropDownTheme)}>
                    <StackView space={2} divider>
                      <TextIconButton
                        loaderRequired={false}
                        content='Edit Status'
                        onClick={() => { handleDeviceActionClick('editStatus') }}
                        theme={dropDownItemTheme}
                        disabled={!props.editAction.editStatus}
                        disableTheme={disabledActionButtonTheme}
                      />
                      <TextIconButton
                        loaderRequired={false}
                        content='Edit Profile'
                        onClick={() => { handleDeviceActionClick('editProfile') }}
                        theme={dropDownItemTheme}
                        disabled={!props.editAction.editProfile}
                        disableTheme={disabledActionButtonTheme}
                      />
                      {<TextIconButton
                        loaderRequired={false}
                        content='Create SIM'
                        onClick={() => handleDeviceActionClick('createSIM')}
                        theme={dropDownItemTheme}
                        disabled={!props.editAction.createSim}
                        disableTheme={disabledActionButtonTheme}
                      />}
                      {(props.editAction.removeSIM) && isTelusAdmin(currentUserRole) && <TextIconButton
                        loaderRequired={false}
                        content='Remove from CMP'
                        onClick={() => handleRemoveDevice()}
                        theme={dropDownItemTheme}
                        disabled={!props.editAction.removeSIM}
                        disableTheme={disabledActionButtonTheme}
                      />}
                       {(isCustomerAdmin(currentUserRole) || isTelusAdmin(currentUserRole) ) && <TextIconButton
                        loaderRequired={false}
                        content='Manage IMEI Lock'
                        onClick={() => { handleDeviceActionClick('enableImeiLock') }}
                        theme={dropDownItemTheme}
                        disabled={!props.editAction.sameImeiStatus}
                        disableTheme={disabledActionButtonTheme}
                      />}
                    </StackView>
                  </Card>
                </DropDown>,
                portalNodeRef.current
              )}
          </StackView>
          {props.tableType === TableType.DEVICE_LIST && <>
          <TextIconButton
            loaderRequired={false}
            content='Export'
            onClick={props.handleExport}
            theme={tableTopButtonTheme}
            iconTheme={tableTopIconTheme}
            icon={DownloadPdf}
          />
          <TableSizeSelect tableType={TableType.DEVICE_LIST}/>
          </>}
        </StackView>
      </StackView>
    </div>
  )
}

export default TableButtons