import { AppSettings, setAdvancedSearchParams, setAppSettings, setCurrentPageable, setTableState, useAppSettings, useTableState } from '@/store/customerManagement';
import { Box, Button, Spacer, List, Modal, Typography } from '@telus-uds/components-web'
import { BulkSimStatusUpdateRequest, BulkSimStatusUpdateResponse, NetworkConfigurationService } from "@/api/index";
import { useState, useEffect } from 'react'
import ConfirmModal from '@/components/ToolBar/ConfirmModal';
import { queryParamsType } from '@/types/global';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { setNotification } from '@/store/submitNotification';
import { TableType } from '@/types/Table';
import { resetAdvancedSearchParams } from '@/components/Table/TableHeader/DataTableSearch';
import { useBulkOperations } from '@/context/BulkOperationsContext';
import { useSelectedRows } from '@/components/Table/DataTable/SelectedRowsProvider';
import { DeviceTableData } from '..';
import { handleApiError } from '@/utils/error-handler';

interface RemoveDeviceProps {
  filteredDevices: any[]
  selectedRows?: DeviceTableData[]
  isModalOpen: boolean
  toggleModal: () => void
  redirect?: string
  setDevicesModal?: (value: boolean) => void
}

const RemoveDevice = ({ filteredDevices, selectedRows, isModalOpen, toggleModal, redirect, setDevicesModal }: RemoveDeviceProps) => {
  const appSettings: AppSettings = useAppSettings();
  const router = useRouter()
  const dispatch = useDispatch()
  const [alert, setAlert] = useState('')
  const { id: customerId } = router.query as unknown as queryParamsType
  const { clearSelectedRows } = useSelectedRows();
  const { addBulkOperation, removeBulkOperation } = useBulkOperations();
  let bulkOperationId: string;

  const handleRemove = () => {
    const ids = filteredDevices.map((item: any) => item.id)
    bulkOperationId = addBulkOperation('Removing Devices', TableType.DEVICE_LIST);
    const data: BulkSimStatusUpdateRequest[] = filteredDevices.map((dataItem) => ({
      simId: Number(dataItem.usimId),
      siteId: Number(dataItem.siteId)
    }));
    clearSelectedRows();
    toggleModal();
    setDevicesModal && setDevicesModal(false);
    NetworkConfigurationService.bulkUpdateSimStatus(customerId, "REMOVED", data)
    .then(async (res: BulkSimStatusUpdateResponse) => {
      dispatch(setNotification({
        message: `Successful Requests: ${res.successfullRequest}, Failed Requests: ${res.failedRequest}, ${res.failedRequest !== 0 ? `Error Message: ${res?.failedSims?.[0].errorMessage}` : ''}`,
        type: res.failedRequest !== 0 ? 'error' : 'success'
      }));
    }).catch((err: any) => {
      handleApiError(err, dispatch);
    }).finally(() => {
      dispatch(setAppSettings({ isLoading: false } as AppSettings));
      dispatch(setCurrentPageable({ page: 0 }));
      dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
      removeBulkOperation(bulkOperationId);
      redirect && router.push(redirect)
    });
  };

  useEffect(() => {
    dispatch(setAppSettings({ isLoading: false } as AppSettings))
  }, [])

  const Content = () => {
    return (
      <Box vertical={4}>
        {filteredDevices.length <= 5 ? (
          <>
            <Typography>{`Do you want to remove the following device${filteredDevices.length > 1 ? 's' : ''} from CMP?`}</Typography>
            <Spacer space={4}/>
            <List variant={{ size: 'medium' }}>
              {filteredDevices.map((user) => (
                <List.Item key={user.id}>{user.name}</List.Item>
              ))}
            </List>
          </>
        ) : (
          <Typography>{`Do you want to remove ${filteredDevices.length} devices from CMP?`}</Typography>
        )}
        <Spacer space={4}/>
        <Typography variant={{ bold: true }}>Removing a device will erase its audit trail</Typography>
      </Box>)
  }

  const CancelButton = () => {
    return (
      <Button
        onClick={() => {
          toggleModal();
        }}
        variant={{  priority: 'low', text: true }}
      >
        Cancel
      </Button>
    );
  }

  return (
    <>
      <div role="alert" aria-live="assertive" aria-atomic="true" id="alert-message" style={{ position: 'absolute', left: '-9999px' }}>{alert}</div>
      <ConfirmModal
        content={Content}
        isOpen={isModalOpen}
        setIsOpen={toggleModal}
        onConfirm={() => {
          setAlert('Confirm selected, devices were removed');
          handleRemove()
        }}
        heading={filteredDevices?.length > 1 ? 'Remove Devices' : 'Remove Device'}
        confirmButtonText="Remove"
        confirmButtonVariant={{ danger: true }}
        cancelButtonType={CancelButton}
      />
    </>
  )
}

export default RemoveDevice
