import { Table } from "@telus-uds/components-web";
import {  TableProps } from "../DataTableTypes";
import { StyledTableWrapper } from "@/styles/theme/styledComponents";
import TableRow from "./DataTableRow";
import { SelectedRowsProvider } from "./SelectedRowsProvider";
import TableRowHeader from "./DataTableRowHeader";
import TableHeader from "../TableHeader/TableHeader";
import TableFooter from "../TableFooter/TableFooter";
import TableSkeleton from "@/components/Table/DataTable/DataTableSkeleton";
import { setCurrentPageable, setGeneralSearchQuery, useAppSettings } from "@/store/customerManagement";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import BulkOperation from "@/components/BulkOperation";
import { Spacer } from "@telus-uds/components-web";
import { TableType } from "@/types/Table";

const DataTable = <T, K extends keyof T>(props: TableProps<T, K>): JSX.Element => {
  const { rowKey, data, columns, isSelectable, isExpandable, expandableFn, setSelectedRows, isSortable, defaultSortKey, defaultSortDirection, tableType, tableButtons, isSearchable= true, page, summaryComponent, tableIndex, background} = props
  const isLoading = !data || data?.length === 0
  const appSettings = useAppSettings()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setGeneralSearchQuery({ searchQuery: "" }))
    return () => {
      dispatch(setCurrentPageable({ page: 0 }))
    }
  },[])
  return (
    <>
      <BulkOperation location={tableType} locationIndex={tableIndex}/>
      <SelectedRowsProvider data={data} setSelectedRows={setSelectedRows} rowKey={rowKey}>
      <TableHeader tableType={tableType} tableButtons={tableButtons} isSearchable={isSearchable}/>
        <StyledTableWrapper>
          <div style={background ? { backgroundColor: background, padding: 0, margin: 0 } : {}}>
            <Table>
              <TableRowHeader
                isExpandable={isExpandable}
                columns={columns}
                isSelectable={isSelectable}
                isSortable={isSortable}
                defaultSortKey={defaultSortKey ?? ''}
                defaultSortDirection={defaultSortDirection}
                tableType={tableType}
                isLoading={isLoading}
              />
              { data
                ? <TableRow
                  rowKey={rowKey}
                  data={data}
                  columns={columns}
                  isSelectable={isSelectable}
                  isExpandable={isExpandable}
                  expandableFn={expandableFn}
                />
                : <TableSkeleton headers={columns}/>}
            </Table>
          </div>
        </StyledTableWrapper>
      </SelectedRowsProvider>
      <TableFooter tableType={tableType} totalPages={page?.totalPages} summaryComponent={summaryComponent}/>
    </>
  )
}
export default DataTable
