import { Spacer, FlexGrid, Divider, StackView, Card, Image, Typography } from '@telus-uds/components-web';
import { NextPage } from 'next';
import { User, clearState, setUser, useIsAutneticated, useUser } from '@/store/customerManagement';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchUser, getRedirectUrl, logoutUser, clearSavedState, getSsoLoginUrl } from '@/utils/utils';
import { clearNotification } from '@/store/submitNotification';

export const handleChange =
  ({ name, onChange }: { name: any, onChange: any }) =>
    (value: any) => {
      onChange({ target: { name, value } });
    }

const LoginPage: NextPage = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const path = router.asPath;
  const [redirected] = useState(path.includes("redirected"));
  const isAuthenticated = useIsAutneticated();
  const user = useUser();
  const redirectUrl = getRedirectUrl(user);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // console.log('LoginPage path check, path: ' + path + ", redirected:" + redirected);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (redirected) {
      fetchUser().then((user: User) => {
        user.isAuthenticated =true;
        dispatch(setUser(user));
        router.replace(redirectUrl);
      }).catch(error => {
        console.log(error);
        logoutUser(dispatch, user);
        window.location.href = getSsoLoginUrl(window.location.origin);
      });
    } else if (isAuthenticated) {
      router.replace(redirectUrl)
    } else {
      window.location.href = getSsoLoginUrl(window.location.origin);
    }
  }, [dispatch, isAuthenticated, redirectUrl, redirected, router]);

  const isNarrowScreen = windowWidth < 1250;

  return (
   <></>
  )
}
export default LoginPage
