import { ImeiType, ModifyLockedImeiRequest, NetworkConfigurationService, UsimConnectionStatusResponse, UsimDataTableViewResponse, UsimSessionInfo, UsimSiteEntryResponse, UsimStatus } from "@/api/index";
import { handleChange } from "@/components/LoginPage";
import { Table } from "@/components/Table";
import { ColumnDefinitionType } from "@/components/Table/DataTableTypes";
import { ColumnLayoutProp } from "@/components/ToolBar/ColumnLayout";
import LabeledValue from "@/components/ToolBar/LabeledValue";
import { LocalTime } from "@/components/ToolBar/LocalTime";
import { useCurrentUserRole, useSelectedCustomer } from "@/store/customerManagement";
import { setNotification } from "@/store/submitNotification";
import { NoWrap } from "@/styles/theme/styledComponents";
import { getStyling, labelTheme } from '@/styles/theme/themes';
import { isCustomerAdmin, isTelusAdmin } from "@/types/RoleMapping";
import { TableType } from "@/types/Table";
import { handleApiError } from "@/utils/error-handler";
import {
  Button,
  Card,
  Divider,
  FlexGrid,
  Icon,
  IconButton,
  Spacer,
  StackView,
  StackWrap,
  TextInput,
  Typography
} from "@telus-uds/components-web";
import {
  LocationMap
} from "@telus-uds/palette-allium/build/rn/icons";
import { Check, Close, Edit } from '@telus-uds/palette-allium/build/web/icons';
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import TableButtons from "./Table/TableButtons";


export const CardWrapper = styled.div`
  z-index: 0 !important;
`

interface DetailsProps {
  simDetails: UsimSiteEntryResponse
  refreshCallback: () => void
  modalOpen: boolean
  setModalOpen: (data: boolean) => void
  fetchUsimDetails: () => Promise<void>;
}
export const Details = (data: ColumnLayoutProp) => {
  return (
    <>
      <FlexGrid limitWidth={false}>
        {data.rows.map((arr: any, rowIndex: any) =>
          (<FlexGrid.Row key={rowIndex}>
            {arr.map(({ key, value, active }: {key: string, value: string, active: boolean}, colIndex: any) => (
              <React.Fragment key= {colIndex}>
                <FlexGrid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <LabeledValue label={key} value={value} active={active}/>
                </FlexGrid.Col>
              </React.Fragment>
            ))}
          </FlexGrid.Row>)
        )}
      </FlexGrid>
    <Spacer space={4} />
    </>
  );
};

const DeviceDetails = ({ simDetails, refreshCallback, modalOpen, setModalOpen, fetchUsimDetails }: DetailsProps) => {
  const buttonRef = useRef(null)
  const router = useRouter()
  const dispatch = useDispatch()
  const currentUserRole = useCurrentUserRole()
  const [sessionData, setSessionData] = useState<UsimSessionInfo[]>([])
  const [connectionStatusData, setConnectionStatusData] = useState<UsimConnectionStatusResponse[]>([])
  const { id: customerId, "site-id": siteId, deviceId } = router.query;
  const {sites} = useSelectedCustomer()
  const currentSite = sites.find(site => site.id === Number(siteId));
  const [editLockedImei, setEditLockedImei] = useState(false)

  const [refresh, setRefresh] = useState(0)
  const selectedData: UsimDataTableViewResponse = {
    siteId: simDetails?.siteId,
    usimId: simDetails?.id,
    imsi: simDetails?.imsi,
    name: simDetails?.name,
    status: simDetails?.status,
    profileName: simDetails?.profileName,
    usimProfileId: simDetails?.profileId,
    msisdn: simDetails?.msisdn,
    description: simDetails?.description,
    dateActivated: simDetails?.dateActivated,
    siteName: simDetails?.siteName,
    imeiLockEnabled: simDetails?.imeiLockEnabled
  }

  const LockedImei = () => {
    const onSubmit = (form: any) => {
      const reqBody: ModifyLockedImeiRequest = {
        lockedImei: form?.lockedImei
      }
      NetworkConfigurationService.modifyLockedImei(Number(customerId), Number(siteId), Number(deviceId), reqBody)
        .then(() => {
          fetchUsimDetails();
          dispatch(setNotification({ message: `Locked IMEI value updated successfully`, type: 'success' }))
        }).catch((error) => {
          handleApiError(error, dispatch);
        }).finally(() => {
          setEditLockedImei(!editLockedImei)
        })
    }
    const onClose = () => {
      setEditLockedImei(!editLockedImei)
    }

    const initialFormData = {
      'lockedImei': simDetails?.lockedImei
    }

    if(editLockedImei) {
      return <Form onSubmit={onSubmit}
      initialValues={initialFormData}
      render={({ handleSubmit}) => (
        <StackWrap space={2} direction="row">
          <NoWrap>
            <Field
              name={`lockedImei`}
              render={({ input, meta }) => (
                <TextInput
                  /* eslint-disable jsx-a11y/no-autofocus */
                  autoFocus
                  value={input.value}
                  inactive={false}
                  onChange={(newValue: string) => {
                    handleChange(input)(newValue);
                  }}
                />
              )}
            />
            <IconButton icon={Check} tokens={{ borderWidth: 0 }} onClick={() => { handleSubmit() }}/>
            <IconButton icon={Close} tokens={{ borderWidth: 0 }} onClick={() => { onClose() }} ref={buttonRef}/>
          </NoWrap>
        </StackWrap>
      )}/>
    }
    return (
      <StackWrap tokens={{ alignItems: 'center' }}>
        <Typography tokens={getStyling(labelTheme)}>
          {simDetails?.lockedImei}
        </Typography>
        {(isCustomerAdmin(currentUserRole) || isTelusAdmin(currentUserRole) && simDetails.imeiLockEnabled && currentSite.imeiType === ImeiType.IMEI_BASED_LOCK) && <IconButton
          icon={Edit}
          tokens={{ borderWidth: 0 }}
          onClick={() =>setEditLockedImei(true)}
        />}
      </StackWrap>
    )
  }

  const active = simDetails?.status === UsimStatus.ACTIVATED
  const deviceDetails: ColumnLayoutProp = {
      rows: [
        [
          { key: 'IMSI', value: simDetails?.imsi },
          { key: 'MSISDN', value: simDetails?.msisdn },
          { key: 'Device Name', value: simDetails?.name},
          { key: 'Description', value: simDetails?.description ? simDetails?.description : 'N/A' },
          { key: 'Status', value: simDetails?.status, active: active },
          { key: 'Date Activated', value: simDetails?.dateActivated ? LocalTime(simDetails?.dateActivated) : 'Not Activated' },
          { key: 'Profile Name', value: simDetails?.profileName ? simDetails?.profileName : 'N/A' },
          { key: 'Date Added', value: LocalTime(simDetails?.dateAdded) },
          { key: 'ICCID', value: simDetails?.iccid ? simDetails?.iccid : 'N/A' },
          { key: "IMEI Lock", value: simDetails?.imeiLockEnabled ? 'Enabled' : 'Disabled' },
          { key: 'Current IMEI', value: simDetails?.currentImei },
          { key: 'Locked IMEI', value: LockedImei() }
        ]
      ]
  }

    const connectionStatusColumns: ColumnDefinitionType<UsimConnectionStatusResponse, keyof UsimConnectionStatusResponse>[] = [
      { key: 'ecmState', header: 'ECM state' },
      { key: 'emmState', header: 'EMM (EMM)'},
      {  key: 'eci',  header: 'Cell id'},
      {  key: 'plmn',  header: 'PLMN'},
      {  key: 'tac',  header: 'TAC'},
      // {  key: 'imei',  header: 'IMEI'},
      {  key: 'ageOfLocationInfo',  header: 'Age of Location'},
      {  key: 'lastRadioContact',  header: 'Last Radio Contact',  renderer: (value) => LocalTime(value as string)}
    ]
    const sessionInformationColumns: ColumnDefinitionType<UsimSessionInfo, keyof UsimSessionInfo>[] = [
      { key: 'apn', header: 'APN' },
      { key: 'qci', header: 'QCI'},
      {
        key: 'ip',
        header: 'IP Address',
        renderer: (value, rowData) => <>
          {rowData.ip.v4 && `${rowData.ip.v4}\n`}
          {rowData.ip.v6 && rowData.ip.v6}
        </>
      },
      {  key: 'rat',  header: 'RAT'},
      {  key: 'usage',  header: 'Usage'}
    ]

  const fetchSessionInformation = useCallback(async () => {
    console.log("fetching session information...", customerId);
    NetworkConfigurationService.getUsimSessionInfo(
      +customerId,
      +siteId,
      +simDetails?.imsi
    )
      .then((UsimSessionInfoResponse) => {
        if (UsimSessionInfoResponse?.usimSessionInfo) {
          setSessionData(UsimSessionInfoResponse.usimSessionInfo);
        } else {
          setSessionData([]);
        }
      })
      .catch((error) => {
        handleApiError(error, dispatch)
      });
  }, [customerId, dispatch, simDetails?.imsi, siteId]);

  const fetchConnectionStatus = useCallback(async () => {
    console.log("fetching event history...", customerId);
    NetworkConfigurationService.getUsimConnectionStatus(
      +customerId,
      +siteId,
      +simDetails?.imsi
    )
      .then((UsimConnectionStatusResponse) => {
        if (UsimConnectionStatusResponse && Object.keys(UsimConnectionStatusResponse).length > 0) {
          setConnectionStatusData([UsimConnectionStatusResponse]);
        } else {
          setConnectionStatusData([]);
        }
      })
      .catch((error) => {
        handleApiError(error, dispatch)
      });
  }, [customerId, dispatch, +simDetails?.imsi, siteId]);

  useEffect(() => {
    if (customerId && siteId && simDetails?.imsi){
      fetchConnectionStatus()
      fetchSessionInformation()
    }
  }, [ customerId, siteId, simDetails?.imsi ]);

  const redirectUrl = `/customer/${customerId}#devices`

  return (
    <>
      <StackView space={2}>
        <Spacer space={3}/>
        <StackWrap tokens= {{ justifyContent: 'space-between' }} >
          <StackView direction="row" tokenes={{ justifyContent: 'center' }}>
            <Button
              variant={{ priority: "high" }}
              onPress={() => {
                // console.log("refresh button pressed");
                setRefresh(refresh + 1)
                refreshCallback()
              }}
            >
              Refresh
            </Button>
          </StackView>
          <StackView>
            <TableButtons
              tableType={TableType.DEVICE_DETAILS}
              editAction={{
                /* Always edittable 
                status is activated/deactivated -> device can have profile change
                status is inventory -> device can be activated
                status is deleted or disabled -> device can be removed
                */
                editable: true, 
                sameImeiStatus: (isTelusAdmin(currentUserRole) || isCustomerAdmin(currentUserRole)) && currentSite?.imeiLockEnabled,
                editStatus: selectedData.status !== UsimStatus.DISABLED && selectedData.status !== UsimStatus.DELETED && selectedData.status !== UsimStatus.INVENTORY,
                editProfile: selectedData.status !== UsimStatus.DISABLED && selectedData.status !== UsimStatus.DELETED && selectedData.status !== UsimStatus.INVENTORY,
                createSim: selectedData.status !== UsimStatus.DISABLED && selectedData.status !== UsimStatus.ACTIVATED && selectedData.status !== UsimStatus.DEACTIVATED, // deleted devices can be created again in HSS
                removeSIM: (selectedData.status === UsimStatus.DELETED || selectedData.status === UsimStatus.DISABLED || selectedData.status === UsimStatus.INVENTORY) && isTelusAdmin(currentUserRole),
                selectedData: [selectedData]
              }}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              handleToggleModal={() => { setModalOpen(!modalOpen) }}
              refreshCallback={refreshCallback}
            />
          </StackView>
        </StackWrap>


        <CardWrapper>
          <Card>
            <StackView space={2}>
              <StackView direction="row" tokenes={{ justifyContent: 'center' }} space={2}>
                <Icon
                  icon={LocationMap}
                  accessibilityLabel="Map icon"
                  variant={{ color: 'brand', size: 'large' }}
                />
                <Typography block variant={{ size: "h3" }}>
                  {`${simDetails?.name} / ${simDetails?.siteName}`}
                </Typography>
              </StackView>
              <Divider />
              {Details(deviceDetails)}
              <Typography variant={{ size: "h4", colour: "alternative2", bold: true }}>
                Connection Status
              </Typography>
              <Table columns={connectionStatusColumns} data={connectionStatusData} isSearchable={false}/>
              <Spacer space={3}/>
              <Typography variant={{ size: "h4", colour: "alternative2", bold: true }}>
                Session Information
              </Typography>
              <Table columns={sessionInformationColumns} data={sessionData} isSearchable={false}/>
            </StackView>
          </Card>
        </CardWrapper>
      </StackView>

    </>
  );
};

export default DeviceDetails;
