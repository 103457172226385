import styled from "styled-components";
import palette from "@telus-uds/palette-allium/build/web/palette";
import paletteAllium from "@telus-uds/palette-allium";
import { TableType } from "@/types/Table";
export const StyledInput = styled.div<{ type?: string }>`
& label {
    font-family: HelveticaNow500normal !important;
}
`
export const Label = styled.div<{ type?: string }>`
, & div {
    font-family: HelveticaNow500normal !important;
}
`
//   flex-grow: 1;    // for site creation
//   & > div {

//     flex-direction: row;
//     padding: ${palette.size.size4};
//     padding-left: 0px;

//     // center the labels
//     & >div: first-child{
//       align-items: center;
//     }

//     // xs screen, make the label and input column
//     @media screen and (max-width: 1041px) {
//       flex-direction: column;
//       align-items:flex-start !important;
//       width: 100% !important;

//       // label
//       & >div: first-child{
//         width: 100% !important;
//       }

//       // input field
//       & >div: last-child {
//         width: 100% !important;
//       }
//     }

//     & label{
//       font-weight: 700;
//     }

//     ${props =>
//       props.type !== 'createSIM'
//        ? `
//           & >div: first-child{
//             width: 8vw;
//             min-width: 8vw;
//           }
//         `
//       : `
//       @media screen and (min-width: 1041px) {
//         & >div: first-child{
//           width: 6vw;
//           min-width: 9vw;
//         }}
//       @media screen and (min-width: 1561px) {
//         & >div: first-child{
//           width: 3vw;
//           min-width: 5vw;
//         }}
//       `
//     }


//     // input field stretch to fill the remaining space
//     & >div: last-child {
//       justify-content: flex-start;
//       flex-grow: 1;
//       flex-shrink: 1;
//     }
//   }
// `
export const StyledHeadingWrapper = styled.div``
// `
//   padding-top: ${palette.size.size24};
//   padding-bottom: ${palette.size.size24};
// `
export const StyledBreadcrumbWrap = styled.div`
  display: flex;
  gap: ${palette.size.size8};
  padding-top: ${palette.size.size8};
  padding-bottom: ${palette.size.size32};
  //setting the link item color to green
  & a {
    transition: color 200ms ease 0s;
    font-size: 15px;
    font-family: HelveticaNow500normal;
    color: ${palette.color.greenAccessible};
  }
  & a:hover {
    transition: color 200ms ease 0s;
    font-size: 15px;
    font-family: HelveticaNow500normal;
    color: ${palette.color.purpleTelus};
  }
  & li {
    align-items: center;
    flex-wrap: wrap;
  }
`
export const StyledLabelValueWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-wrap: anywhere;
  margin: 0 0;

  & div {
    font-size: 16px !important;
    font-family: HelveticaNow500normal !important;
}
  
  // xs screen
  @media screen and (max-width: 636px) {
    flex-direction: column;
    &div: first-child {
      width: 100% !important; //label to take full width
    }
  }

  // xl screen: PC
  @media screen and (min-width: 1600px) {
    &  div: first-child{
    // width: 30% !important;
   //  min-width: ${paletteAllium.size.size1}vw !important;
    }
  }

  // normal laptop screens : label
  & > div:first-child{
    width: 30%;
    margin-right: ${palette.size.size15};
    min-width: 8vw;
    margin-right: ${palette.size.size2};
  }

  //value
  & > div:last-child{
    overflow-wrap: anywhere;    //no-wrap;  to make the value to be on a single line
  }
`;
export const AdvancedSearchWrapper = styled.div`
  margin: 10px 0;
`
// `
//   padding-top: ${palette.size.size16};
//   display: flex;
//   & > form {
//     flex-basis: 100%;
//     box-shadow: ${palette.shadow.elevation1};
//       @media (min-width: 1220px) {
//         flex-basis: 60%; /* Set width to 50% on XL screens */
//       }
//   }
// `
export const FormButtonContainer = styled.div<{ type?: string, space?: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${palette.size.size26};
  padding-top: ${props => props.type === 'search' ? `${palette.size.size8}` : `${props.space ?? palette.size.size48}`};
`

// we have to wrap each card with cardcontainer as StackWrap may cause undesired behavior
// by adjusting card sizes instead of moving to the next line
export const CardContainer = styled.div`
  flex-basis: calc(50% - ${palette.size.size16});
  flex-grow: 1;
  flex-shrink: 1;
`;
export const StyledLinkWrapper = styled.div<{ tableType?: string }>`
  padding-left: ${(props) =>
    props.tableType !== TableType.DEVICE_LIST
      ? `${palette.size.size26}`
      : `${palette.size.size0}`};
  display: inline;
  font-weight: 700;
   & a{
    color: ${palette.color.greenSanFelix};
   }
`
export const NoWrap = styled.div`
  white-space: nowrap;
  display: flex;
  gap: 2px;
  align-items: center;
`
export const CompactTableCell = styled.td `
  width: 20px !important;
  box-shadow: inset 0 1px 0 #e3e6e8;
  padding-left: 16px;
`
export const StyledTableWrapper = styled.div<{ tableType?: string }>`
border: 1px solid ${palette.color.greyMystic};
border-radius: ${palette.radius.radius6};
box-shadow: ${palette.shadow.elevation1};
position: relative;
z-index: 0 !important;

  & div,span {
    font-size: ${palette.fontSize.size14} !important;
  }


  & > div {
    max-height: 80vh;
    min-height: 0;
  }
  & table {
    width: 100%;
  }
  & thead {
    position: sticky;
    top: 0;
    z-index: 999999;
  }

  // &td: last-child {
  //   width: 20px;
  // }
  //  for event history table
  & .jsonview-code {
    display: block !important;
    padding: 0.5rem !important;
    background-color: rgb(40, 44, 52) !important;
    color: rgb(136, 198, 190) !important;
    overflow-x: scroll !important;
    border-radius: 5px !important;

    & .jsonview-line { white-space: nowrap !important; }
    & .jsonview-hi-line { white-space: nowrap !important; background-color: rgb(80, 80, 95) !important; }
  
    & .jsonview-str { color: rgb(121, 182, 242) !important; }
    & .jsonview-num { color: rgb(255, 255, 200) !important; }
    & .jsonview-bool { color: rgb(197, 165, 197) !important; }
    & .jsonview-null { color: rgb(197, 165, 197) !important; }
    & .jsonview-prop { color: rgb(250, 200, 99) !important; }
  
    & .jsonview-ln {
      display: inline-block !important;
      padding-right: 1rem !important;
      color: rgb(178, 178, 178) !important;
      text-align: right !important;
      user-select: none !important;
    }
  }
  
`

export const JsonView = styled.div`
  & .jsonview-code {
    max-width: 25vw;
    display: block !important;
    padding: 0.5rem !important;
    background-color: rgb(40, 44, 52) !important;
    color: rgb(136, 198, 190) !important;
    overflow-x: scroll !important;
    border-radius: 5px !important;

    & .jsonview-line { white-space: nowrap !important; }
    & .jsonview-hi-line { white-space: nowrap !important; background-color: rgb(80, 80, 95) !important; }
  
    & .jsonview-str { color: rgb(121, 182, 242) !important; }
    & .jsonview-num { color: rgb(255, 255, 200) !important; }
    & .jsonview-bool { color: rgb(197, 165, 197) !important; }
    & .jsonview-null { color: rgb(197, 165, 197) !important; }
    & .jsonview-prop { color: rgb(250, 200, 99) !important; }
  
    & .jsonview-ln {
      display: inline-block !important;
      padding-right: 1rem !important;
      color: rgb(178, 178, 178) !important;
      text-align: right !important;
      user-select: none !important;
    }
  }`

export const StyledServiceProfileTableWrapper = styled.div<{ tableType?: string }>`
  border: 1px solid ${palette.color.greyMystic};
  border-radius: ${palette.radius.radius6};
  box-shadow: ${palette.shadow.elevation1};
  position: relative;
  z-index: 0 !important;

  & > div {
    max-height: 80vh;
    min-height: 0;
  }
  & table {
    width: 100%;
    table-layout: fixed;
  }
  & thead {
    position: sticky;
    top: 0;
    z-index: 999999;
  }
  & th, & td {
    width: 25%;
  }
  & td:last-child {
    width: 25%;
  }
  // Uncomment and modify the following if needed for specific functionality
  // & td > div {
  //   position: static; // for popover
  // }
`;
// ``
// margin-top: ${palette.size.size32};
// border: 1px solid ${palette.color.greyMystic};
// border-radius: ${palette.radius.radius6};
// box-shadow: ${palette.shadow.elevation1};
// position: relative;
// z-index: 0 !important;
// & div {
//   font-size: ${palette.fontSize.size16} !important;
// }
// & table {
//   width: 100%;
//   overflow:hidden;


//   // centering the headers who has the sort capability
//   & thead> tr {

//     // defining margin for customer accounts table
//     & >th: first-child>div{
//       margin-left: ${props => props.tableType !== TableType.USER_LIST ? `${palette.size.size48}` : `${palette.size.size0}`};
//     }

//     // adjusting the space of the 'Name' in Users tab
//     & > td: nth-child(2) > div a{
//       margin-left: ${props => props.tableType === TableType.USER_LIST ? `${palette.size.size56}` : `${palette.size.size0}`};
//     }

//     ${props =>
//       props.tableType === TableType.DEVICE_LIST &&
//       `
//         & > td > div {
//           display: flex;
//           // height: 64px;
//           justify-content: center;
//           align-items: center;
//         }
//       `
//     }

//     ${props =>
//       props.tableType === TableType.DEVICE_LIST &&
//       `
//         & td > div {
//           justify-content: flex-start;
//         }
//       `
//     }

//     & a{
//       position: relative;
//       top: 70%;
//     }
//   }
//   //removing the inbuilt box-shadow of Header, and setting the height
//   & th{
//     // height: ${palette.size.size64};
//     box-shadow: none;
//     // center teh contents of header
//     & > div {
//       position: relative;
//       top: 30%;
//     }

//   }

//   // adjusting the margin of first cell in event history
//   & tbody> tr> td: first-child> div> div{
//     margin-left: ${props => props.tableType === TableType.EVENT_HISTORY ? `${palette.size.size56}` : `${palette.size.size0}`};
//   }


//   & thead>tr>td>div>div {
//     align-items: left ; // Center Header with Row Stackview
//   }

//   // making the entire data to appear on a single line
//   & tbody>tr>td>div{
//     white-space:nowrap;
//   }

//   & tbody>tr>td:not(:first-child) {
//     min-width: 180px;
//   }

//   // removing the inbuilt shadow for the td element in expand collapse
//   & tbody>tr>div>div>div[role=button]>td{
//     border-bottom:0px ;
//     box-shadow: none;
//   }
//  }
// `
export const StyledExpandButton = styled.button`
  // padding: ${palette.size.size2};
  display: flex;
  height: 28px;
  align-items: center;
  border: ${palette.border.none};
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.1s linear;
`;
export const ModalContentWrapper = styled.div`

 padding: ${palette.size.size24} ${palette.size.size4};
 & > div {
  padding-top: ${palette.size.size24};
  padding-bottom: ${palette.size.size24};
 }
`
export const ModalWrapper = styled.div`
&  div{
  overflow-x: unset; //removes the scrollbar from the modal
}
`
export const PopOver = styled.div<{ type?: string }>`
  z-index: 999999 !important;
  position: absolute;
  box-shadow: ${palette.shadow.elevation1};
`

export const PopOverWrapper = styled.div`
  & > div {
    position: sticky;
  }
`
export const DropDown = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  box-shadow: ${palette.shadow.elevation1};
`;

export const ScrollableTable = styled.div`
  height: 500px;
  overflow: auto;

  & table {
    border-collapse: collapse;

    & thead tr th {
      position: sticky;
      top: 0;
    }

    & th {
      /* With border-collapse, we must use box-shadow or psuedo elements
      for the header borders */
      box-shadow: 0px 0px 0 2px #e8e8e8;
    }
  }

  
`
